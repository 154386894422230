import request from "@/Rest";
import { STRIPE_URLS } from "@/Rest/api/urls";

export async function createClient(email, name, isFreeTrial = false) {
  const body = {
    email,
    name,
    isFreeTrial
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createClient, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createSubscription({
  customerId,
  paymentMethodId,
  priceId,
  quantity,
  proration,
  facilityCode,
  couponId = "",
  discountPercentage = 0.0,
  isFreeTrial = false,
}) {
  const body = {
    customerId,
    paymentMethodId,
    priceId,
    quantity,
    couponId,
    proration,
    facilityCode,
    discountPercentage,
    isFreeTrial,
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createSubscription, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createB2CUser({
  customerId,
  quantity,
  coupon,
  facilityCode,
}) {
  const body = {
    customerId,
    quantity,
    coupon,
    facilityCode,
  };
  try {
    await request.POST(STRIPE_URLS.createB2CUser, body);
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function buySeats(
  paymentMethodId,
  priceId,
  quantity,
  couponId = ""
) {
  const body = {
    ...(paymentMethodId !== null ? { paymentMethodId: paymentMethodId } : {}),
    priceId,
    quantity,
    couponId,
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createSeats, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createPay(
  customerId,
  paymentMethodId,
  quantity,
  amount,
  priceId,
  couponId = ""
) {
  const body = {
    customerId,
    paymentMethodId,
    quantity,
    amount,
    couponId,
    priceId,
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createPay, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getPromotionCodes() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getPromotionCodes);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getProductInfo(code, location) {
  try {
    const { data } = await request.GET(
      STRIPE_URLS.getProduct(code ?? "", location ?? "")
    );

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getStripeDiscountCode(discountCode) {
  try {
    const params = { discount_code: discountCode };
    const { data } = await request.GET(STRIPE_URLS.getStripeDiscountCode, {
      params,
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getSubscriptionsSummary() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getSubscriptionsSummary);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getMonthlyRevenueSummary() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getMonthlyRevenueSummary);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getUserSubscriptionsSummary() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getUserSubscriptionsSummary);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getOrganizationsSummary() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getOrganizationsSummary);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getOrganizationUsersSummary() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getOrganizationUsersSummary);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function cancelFullSubscription() {
  try {
    await request.POST(STRIPE_URLS.cancelFullSubscription);
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function cancelPartialSubscription(dependentProfileId) {
  try {
    const payload = {
      dependentProfileId,
    };
    await request.POST(STRIPE_URLS.cancelPartialSubscription, payload);
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function getStripeSubscriptionInfo() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getStripeSubscriptionInfo);

    return data;
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function subscriptionNextInvoiceTotalBeforeQuantityChange(action) {
  try {
    const params = { action };
    const { data } = await request.GET(
      STRIPE_URLS.subscriptionNextInvoiceTotalBeforeQuantityChange,
      { params }
    );

    return data;
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function subscriptionNextInvoiceTotal(newSubscriptionId) {
  try {
    const params = { newSubscriptionId };
    const { data } = await request.GET(
      STRIPE_URLS.subscriptionNextInvoiceTotal,
      { params }
    );

    return data;
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function reactiveSubscription(dependentsIds) {
  try {
    const payload = { quantity: dependentsIds.length };

    const { data } = await request.POST(STRIPE_URLS.reactiveSubscription, payload);
    
    return data;
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function reactivePartialSubscription(dependentProfileId) {
  try {
    const payload = {
      dependentProfileId,
    };
    const { data } = await request.POST(STRIPE_URLS.reactivePartialSubscription, payload);
    
    return data;
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function confirmSubscriptionReactivation(paymentIntentId, newSubscriptionId, selectedDependentsIds) {
  try {
    const payload = { 
      payment_intent_id: paymentIntentId,
      subscription_id: newSubscriptionId,
      dependents_ids: selectedDependentsIds
    };
    await request.POST(STRIPE_URLS.confirmSubscriptionReactivation, payload);
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function attachPaymentMethod(paymentMethodId) {
  try {
    const payload = { payment_method_id: paymentMethodId };
    await request.POST(STRIPE_URLS.attachPaymentMethod, payload);
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}
