import request from "@/Rest";
import { APP_STORE_URLS } from "@/Rest/api/urls";

export async function getAppStoreTransactionInfo() {
  try {
    const { data } = await request.GET(
      APP_STORE_URLS.getAppStoreTransactionInfo
    );

    return data;
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function appStoreDeactivateUsers(
  dependentsIds,
  deactivateAllDependents
) {
  try {
    const payload = {
      dependents_ids: dependentsIds,
      deactivate_all_dependents: deactivateAllDependents,
    };
    await request.POST(APP_STORE_URLS.appStoreDeactivateUsers, payload);
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function dependentsToAddOrReactivate() {
  try {
    const { data } = await request.GET(
      APP_STORE_URLS.dependentsToAddOrReactivate
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function appStoreReactivateUsers(
  dependentsIds,
  reactivateAllDependents
) {
  try {
    const payload = {
      dependents_ids: dependentsIds,
      reactivate_all_dependents: reactivateAllDependents,
    };
    await request.POST(APP_STORE_URLS.appStoreReactivateUsers, payload);
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}
